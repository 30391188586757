.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;

  img {
    width: 100%;
    height: 95%;
    object-fit: cover;
  }

  button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 255px;
    display: none;
  }

  &:hover {
    // img {
    //   opacity: 0.8;
    // }

    ::after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        120deg,
        rgba(238, 174, 202, 0.15) 0%,
        rgba(148, 187, 233, 0.15) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      height: 95%;
    }

    button {
      opacity: 0.85;
      display: flex;
    }
  }

  .footer {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;

    .name {
      margin-top: 5px;
      width: 90%;
      margin-bottom: 15px;
    }

    .price {
      margin-top: 5px;
      width: 10%;
    }
  }

  &:hover {
    button {
      opacity: unset;
    }
  }
}
