.button-container {
  min-width: 160px;
  width: auto;
  height: 50px;
  letter-spacing: 0.1px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 14px;
  background-color: #211614;
  color: #fff6f6;
  text-transform: uppercase;
  // font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  @media screen and(max-width:800px) {
    display: block;
    padding: 0 20px;
  }

  &:hover {
    background-color: #fff6f6;
    color: #211614;
    border: 1px solid #211614;
    transition-timing-function: ease-in;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: #fff6f6;

    &:hover {
      background-color: #2e6bcc;
      border: none;
    }
  }

  &.inverted {
    background-color: #fff6f6;
    color: #211614;
    // border: 1px solid #211614;

    &:hover {
      background-color: #211614;
      color: #fff6f6;
      border: none;
    }
  }
}
