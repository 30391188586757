@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
.video {
  width: 100%;
  height: 40%;
  margin-bottom: 20px; }

.text {
  position: absolute;
  font-family: 'Raleway';
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 120px;
  text-shadow: 0px 0px 2px #000, 0px 0px 4px #000;
  opacity: 0.7;
  top: 60%;
  z-index: 3;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-style: bold; }

@media screen and (max-width: 850px) {
  .text {
    font-size: 40px;
    top: 22%; } }

.directory-item-container {
  min-width: 30%;
  height: 320px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7.5px 15px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(27, 27, 27, 0.1); }
  .directory-item-container:hover {
    cursor: pointer; }
    .directory-item-container:hover .background-image {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      transition: -webkit-transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95), -webkit-transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95); }
    .directory-item-container:hover .directory-item-body {
      opacity: 0.9; }
    .directory-item-container:hover ::after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(120deg, rgba(238, 174, 202, 0.2) 0%, rgba(148, 187, 233, 0.2) 100%);
      position: absolute;
      top: 0;
      left: 0; }
  .directory-item-container.large {
    height: 380px; }
  .directory-item-container:first-child {
    margin-right: 7.5px; }
  .directory-item-container:last-child {
    margin-left: 7.5px; }
  .directory-item-container .background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; }
  .directory-item-container .directory-item-body {
    height: 90px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: 0.7;
    position: absolute; }
    .directory-item-container .directory-item-body h2 {
      font-weight: 400;
      font-size: 22px;
      color: #252525d3;
      text-transform: uppercase;
      margin: 6px 0; }
    .directory-item-container .directory-item-body p {
      font-weight: 400;
      font-size: 16px;
      color: #252525bd;
      text-transform: uppercase;
      margin: 6px 0; }
  @media screen and (max-width: 800px) {
    .directory-item-container {
      height: 220px; } }

.directory-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.cart-icon-container {
  width: 45px;
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .cart-icon-container .shopping-icon {
    width: 24px;
    height: 24px; }
  .cart-icon-container .item-count {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    bottom: 12px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.button-container {
  min-width: 160px;
  width: auto;
  height: 50px;
  letter-spacing: 0.1px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 14px;
  background-color: #211614;
  color: #fff6f6;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .button-container {
      display: block;
      padding: 0 20px; } }
  .button-container:hover {
    background-color: #fff6f6;
    color: #211614;
    border: 1px solid #211614;
    transition-timing-function: ease-in;
    transition-duration: 0.2s; }
  .button-container.google-sign-in {
    background-color: #4285f4;
    color: #fff6f6; }
    .button-container.google-sign-in:hover {
      background-color: #2e6bcc;
      border: none; }
  .button-container.inverted {
    background-color: #fff6f6;
    color: #211614; }
    .button-container.inverted:hover {
      background-color: #211614;
      color: #fff6f6;
      border: none; }

.cart-item-container {
  width: 100%;
  display: flex;
  height: 80px;
  margin-bottom: 15px; }
  .cart-item-container img {
    width: 30%; }
  .cart-item-container .item-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px; }
    .cart-item-container .item-details .name {
      font-size: 16px; }

.cart-dropdown-container {
  position: absolute;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #252525a9;
  background-color: #fff6f6;
  opacity: 0.9;
  top: 90px;
  right: 40px;
  z-index: 5; }
  .cart-dropdown-container .empty-message {
    font-size: 18px;
    margin: 50px auto; }
  .cart-dropdown-container .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: scroll; }
  .cart-dropdown-container button {
    margin-top: auto; }

.navigation {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px; }
  @media screen and (max-width: 800px) {
    .navigation {
      height: 60px;
      padding: 10px;
      margin-bottom: 20px; } }
  .navigation .logo-container {
    height: 100%;
    width: 70px;
    padding: 15px 15px; }
    @media screen and (max-width: 800px) {
      .navigation .logo-container {
        width: 50px;
        padding: 0 10px; } }
  .navigation .nav-links-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    @media screen and (max-width: 800px) {
      .navigation .nav-links-container {
        width: 80%; } }
    .navigation .nav-links-container .nav-link {
      padding: 10px 15px;
      cursor: pointer; }

.group {
  position: relative;
  margin: 45px 0; }
  .group .form-input {
    background: none;
    color: grey;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;
    margin: 25px 0; }
    .group .form-input:focus {
      outline: none; }
    .group .form-input:focus ~ .form-input-label {
      top: -14px;
      font-size: 12px;
      color: #252525; }
  .group input[type='password'] {
    letter-spacing: 0.3em; }
  .group .form-input-label {
    color: grey;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all; }
    .group .form-input-label.shrink {
      top: -14px;
      font-size: 12px;
      color: #252525; }

.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px; }
  .sign-up-container h2 {
    margin: 10px 0; }

.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px; }
  .sign-up-container h2 {
    margin: 10px 0; }
  .sign-up-container .buttons-container {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 800px) {
      .sign-up-container .buttons-container {
        padding: 0 10px; } }

.authentication-container {
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 30px auto; }

.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative; }
  .product-card-container img {
    width: 100%;
    height: 95%;
    object-fit: cover; }
  .product-card-container button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 255px;
    display: none; }
  .product-card-container:hover ::after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(238, 174, 202, 0.15) 0%, rgba(148, 187, 233, 0.15) 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 95%; }
  .product-card-container:hover button {
    opacity: 0.85;
    display: flex; }
  .product-card-container .footer {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    font-size: 18px; }
    .product-card-container .footer .name {
      margin-top: 5px;
      width: 90%;
      margin-bottom: 15px; }
    .product-card-container .footer .price {
      margin-top: 5px;
      width: 10%; }
  .product-card-container:hover button {
    opacity: unset; }

.category-preview-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }
  .category-preview-container .title {
    font-size: 28px;
    margin-bottom: 25px;
    cursor: pointer; }
  .category-preview-container .preview {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px; }
    @media screen and (max-width: 800px) {
      .category-preview-container .preview {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
        grid-row-gap: 50px;
        row-gap: 50px;
        margin-bottom: 50px; } }

.category-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 50px;
  row-gap: 50px;
  margin-bottom: 50px; }
  @media screen and (max-width: 800px) {
    .category-container {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 10px;
      -webkit-column-gap: 10px;
              column-gap: 10px; } }

.category-title {
  font-size: 38px;
  margin-bottom: 25px;
  text-transform: uppercase;
  text-align: center; }

.products-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 50px;
  row-gap: 50px; }

.checkout-item-container {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center; }
  .checkout-item-container .image-container {
    width: 33%;
    padding-right: 15px; }
    .checkout-item-container .image-container img {
      width: 100%;
      height: 100%; }
  .checkout-item-container .name,
  .checkout-item-container .quantity,
  .checkout-item-container .price {
    width: 23%; }
  .checkout-item-container .quantity {
    display: flex; }
    .checkout-item-container .quantity .arrow {
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
      padding: 0 12px; }
      @media screen and (max-width: 800px) {
        .checkout-item-container .quantity .arrow {
          padding: 0 3px; } }
    .checkout-item-container .quantity .value {
      margin: 0 10px;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .checkout-item-container .remove-button {
    padding-left: 12px;
    cursor: pointer; }

.checkout-container {
  width: 75%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0; }
  @media screen and (max-width: 800px) {
    .checkout-container {
      width: 90%;
      grid-column-gap: 20px;
      -webkit-column-gap: 20px;
              column-gap: 20px; } }
  .checkout-container .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey; }
    .checkout-container .checkout-header .header-block {
      text-transform: capitalize;
      width: 23%; }
      .checkout-container .checkout-header .header-block:last-child {
        width: 8%; }
    @media screen and (max-width: 800px) {
      .checkout-container .checkout-header {
        width: 100%;
        letter-spacing: 0.05px;
        padding: 0 20px;
        font-size: 14px; }
        .checkout-container .checkout-header .header-block {
          display: none; } }
  .checkout-container .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 36px; }
    @media screen and (max-width: 800px) {
      .checkout-container .total {
        font-size: 26px; } }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 20px 40px;
  font-family: 'Montserrat', sans-serif;
  color: #252525;
  background-color: #fff6f6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (max-width: 800px) {
    body {
      padding: 10px; } }

a {
  text-decoration: none;
  color: #252525; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

