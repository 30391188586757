.checkout-container {
  width: 75%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;

  @media screen and(max-width:800px) {
    width: 90%;
    column-gap: 20px;
  }

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    .header-block {
      text-transform: capitalize;
      width: 23%;

      &:last-child {
        width: 8%;
      }
    }

    @media screen and(max-width:800px) {
      width: 100%;
      letter-spacing: 0.05px;
      padding: 0 20px;
      font-size: 14px;

      .header-block {
        display: none;
      }
    }
  }

  .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 36px;

    @media screen and(max-width:800px) {
      font-size: 26px;
    }
  }
}
