@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

.video {
  width: 100%;
  height: 40%;
  margin-bottom: 20px;
}

.text {
  position: absolute;
  font-family: 'Raleway';
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 120px;
  text-shadow: 0px 0px 2px #000, 0px 0px 4px #000;
  opacity: 0.7;
  top: 60%;
  z-index: 3;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: bold;
}
@media screen and (max-width: 850px) {
  .text {
    font-size: 40px;
    top: 22%;
  }
}
