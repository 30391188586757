.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px;

  h2 {
    margin: 10px 0;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    @media screen and(max-width:800px) {
      padding: 0 10px;
    }
  }
}
