.category-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 50px;
  margin-bottom: 50px;

  @media screen and(max-width:800px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
  }
}

.category-title {
  font-size: 38px;
  margin-bottom: 25px;
  text-transform: uppercase;
  text-align: center;
}
