.directory-item-container {
  min-width: 30%;
  height: 320px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid rgba(0, 0, 0, 0.384);
  margin: 0 7.5px 15px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(27, 27, 27, 0.1);

  &:hover {
    cursor: pointer;

    & .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .directory-item-body {
      opacity: 0.9;
    }

    ::after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        120deg,
        rgba(238, 174, 202, 0.2) 0%,
        rgba(148, 187, 233, 0.2) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      // transform: scale(1.06);
    }
  }

  &.large {
    height: 380px;
  }

  &:first-child {
    margin-right: 7.5px;
  }

  &:last-child {
    margin-left: 7.5px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .directory-item-body {
    height: 90px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid rgb(190, 190, 190);
    background-color: white;
    opacity: 0.7;
    position: absolute;

    h2 {
      font-weight: 400;
      font-size: 22px;
      color: #252525d3;
      text-transform: uppercase;
      margin: 6px 0;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #252525bd;
      text-transform: uppercase;
      margin: 6px 0;
    }
  }

  @media screen and(max-width:800px) {
    height: 220px;
  }
}
